import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {FaAngleLeft, FaPlus} from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import OtherToolbar from '../../Layout/OtherToolbar/OtherToolbar';
import Content from '../../Layout/Content/Content';

const styles = theme => {
    console.log(theme)
    return ({
    root: {},
    paper:{ margin: theme.spacing.unit },
    toolbar: { },
    
    // table: {
    //     minWidth: 700
    // },
    
})};

const UsersList = (props) => {
    const data = { ...props }
    const { classes } = props;
    console.log("users::::::::::::::", data.response);

    // return (
    //     <div>
    //         { data.response ? data.response.map( e => console.log("elem:", e.name)): null}
    //     </div>
    // )

    return (
        <React.Fragment>
             { data.response  ? (
                <div>
                    <OtherToolbar>
                    <Grid container   direction="row" justify="space-between" alignItems="center" >
                        
                    <Grid item >
                    <Button variant="fab" color="primary" mini onClick = { ()=>{props.history.push('/landing')} }>
                            <FaAngleLeft style = {{fontSize: '2em'}} />
                    </Button>
                        
                        {/* </Grid> */}
                    </Grid>
                    <Grid item>
                    <Typography variant = "h6" color = "primary"><strong>LISTĂ UTILIZATORI </strong></Typography>
                    </Grid>
                    
                    <Button variant="fab" color="primary" mini onClick = {props.addUserHandler}>
                        <FaPlus style = {{fontSize: '2em'}} />
                    </Button>
                    </Grid>
                    </OtherToolbar>
                    <Content>
                    <Paper className={classes.paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className = {classes.table}>
                                <TableCell>ID</TableCell>
                                <TableCell >Nume</TableCell>
                                <TableCell >Utilizator</TableCell>
                                {/* <TableCell >Email</TableCell>
                                <TableCell >Google ID</TableCell>
                                <TableCell >Facebook ID</TableCell> */}
                            </TableRow>
                        </TableHead>
                       
                        <TableBody>
                            {data.response.map((row, index) => {
                                return (
                                    <TableRow key={row.id} onClick = {() => props.userHandler(row)} 
                                        onContextMenu = {event => {event.preventDefault();props.contextHandler(row)}}
                                        
                                        >
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.username}</TableCell>
                                        {/* <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.google_id}</TableCell>
                                        <TableCell>{row.facebook_id}</TableCell> */}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        
                    </Table>
                </Paper>
                </Content>
                </div>
            ) : null}
        </React.Fragment>
    )
}

UsersList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UsersList));