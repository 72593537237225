import React from 'react';
import ToolbarTop from './ToolbarTop/ToolbarTop';
// import Error from '../Error/Error';

import Typography from '@material-ui/core/Typography';

class Layout extends React.Component {

    render() {
        return (
            <div>
            <Typography />
            <ToolbarTop />
            {/* <Grid
                container
                direction="column"
                alignItems="stretch"
                style = {{minHeight:"90vh"}}
                >

                    <Paper>
                        {this.props.children}
                    </Paper>

            </Grid> */}
            {this.props.children}
        </div>
        )
    }
    
}

export default Layout