import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {},
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    textFieldHalf: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '48%',
    },    
    dense: {    
        marginTop: 19,
    },
    menu: {
        width: 400,
    },
    input: {
        display: 'none',
    },
    button: { },
    toolbarButtons: {}
});

class UserDetails extends React.Component {

    state = { ...this.props };

   
    render() {
        // console.log("USER DETAILS STATE:",this.state)
        const { classes } = this.props;

        return (
           <React.Fragment>
           
            { this.props.dataLoaded  ? (

                <form className={classes.container} noValidate autoComplete="Off">
                {/* {this.state.user.name} */}
                    <TextField
                        id="nume"
                        label="Nume și prenume"
                        className={(classes.margin, classes.textField)}
                        variant="outlined"
                        value={this.state.user.name}
                        onChange={this.props.userDetailsHandler('name')}
                        margin="normal"
                        type="text"
                    />

                    <TextField
                        id="email" 
                        label="Adresa de email"
                        className={(classes.margin, classes.textField)}
                        variant="outlined"
                        value={this.state.user.email}
                        onChange={this.props.userDetailsHandler('email')}
                        margin="normal"
                        type="text"
                    />

                    <TextField
                        id="utilizator"
                        label="Utilizator"
                        className={(classes.margin, classes.textField)}
                        variant="outlined"
                        value={this.state.user.username}
                        onChange={this.props.userDetailsHandler('username')}
                        margin="normal"
                        type="text"

                    />

                    <TextField
                        id="parola"
                        label="Parola"
                        className={(classes.margin, classes.textField)}
                        variant="outlined"
                        value={this.state.user.password}
                        onChange={this.props.userDetailsHandler('password')}
                        margin="normal"
                        type="password"
                    />

                    <TextField
                        id="google-id"
                        label="ID Google"
                        className={(classes.margin, classes.textFieldHalf)}
                        variant="outlined"
                        value={this.state.user.google_id || ''}
                        onChange={this.props.userDetailsHandler('google_id')}
                        margin="normal"
                        type="text"
                    />

                    <TextField
                        id="facebook-id"
                        label="ID Facebook"
                        className={(classes.margin, classes.textFieldHalf)}
                        variant="outlined"
                        value={this.state.user.facebook_id || ''}
                        onChange={this.props.userDetailsHandler('facebook_id')}
                        margin="normal"
                        type="text"
                    />
                </form>
            
            ) 
            : (<div>se incarca datele...</div>)
            }
            </React.Fragment>
        )
    }

}

export default withStyles(styles)(UserDetails);