import React from 'react';

// import Config from '../../../Config';
import Error from '../../../components/Error/Error';
import UsersList from './UsersList';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Axios } from '../../../Utils';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogTitle';

// const config = Config[process.env.NODE_ENV]

const styles = theme => {

    return ({
        root: {},
        toolbar: { background: 'lightblue', padding: theme.spacing.unit },
    })

}

let response = null;

class Users extends React.Component {

    state = {
        response: null,
        error: false,
        status: 200,
        msg: null,
        loaded: false,
        dialog: { open: false, confirmed: false },

    }

    async componentDidMount() {

        // let lsObject = localStorage.getItem(config.NAMESPACE)
        // lsObject = JSON.parse(lsObject)
        // let token =lsObject.token;
        try {
            response = await Axios({
                method: 'POST',
                url: '/api/v1/find',
                data: { table: 'users' },
                // headers:    { 'x-auth-token' : token }

            })
        }
        catch (err) {
            console.log("error:", err);
            this.setState({ error: true, msg: err.message, status: 401 })
        }
        finally {

            if (response && response.data) {
                this.setState({ response: response.data, loaded: true })
            } else {
                this.setState({ error: true, msg: 'Interogarea sql a esuat, probabil rolul Dvs. nu permite accesul', status: 401 })
            }

        }

    }

    userHandler = (props) => {
        this.props.history.push({
            pathname: '/user/' + props.id,

            data: { ...props }
        })

    }

    contextHandler = event => {

        console.log(' event:', event);
        this.setState({
            dialog: {
                open: true,
                event: event,
                content: `Operațiunea este definitivă! Doriți să continuați cu ștergerea??`,
                title: `Ștergere ${event.username} !!!`
            }
        })
    }

    okHandler = async () => {
        console.log("se sterge...", this.state.dialog.event)
        let response = null;
        try {
            response = await Axios({
                method: 'POST',
                url: '/api/v1/remove',
                data: { table: 'users', id: this.state.dialog.event.id },
            })
        } catch (err) { console.log("remove item error:", err) }
        finally {
            let users = [...this.state.response]
            let user = (users.filter(elem => elem.id === response.data.id))[0]
            users.splice(users.indexOf(user), 1)
            this.setState({ response: users, dialog: { open: false } });

        }
    }

    addUserHandler = () => {

        this.props.history.push('/user-add')
    }

    render() {
        const { classes } = this.props
        console.log(this.props)
        return (
            <div>

                {!!this.state.error ? <Error {...{ status: 401, msg: this.state.msg }} /> : null}
                {!!this.state.loaded
                    ?
                    <div>

                        <Dialog open={this.state.dialog.open}
                            disableBackdropClick={false}
                            onBackdropClick = {()=> this.setState({dialog:{open: false}})}
                            size="small"
                            onClose={this.state.dialog.rejectAction ? this.state.dialog.rejectAction : null}>

                            <DialogTitle> {this.state.dialog.title ? this.state.dialog.title : 'ATENȚIONARE'}</DialogTitle>
                            <DialogContent>
                                <DialogContentText> {this.state.dialog.content ? this.state.dialog.content : 'Operațiunea va fi efectuată! Confirmați?'} </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ dialog: { confirmed: false, open: false } })}>NU</Button>
                                <Button onClick={this.okHandler} > DA </Button>
                            </DialogActions>
                        </Dialog>
                        <UsersList {...this.state}
                            userHandler={this.userHandler}
                            addUserHandler={this.addUserHandler}
                            contextHandler={this.contextHandler} />

                    </div>
                    : <p>incarc rezultatele...</p>}
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Users));