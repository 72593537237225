import React from 'react';
import { withRouter } from 'react-router-dom';

import Config from '../../../Config';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import UserApps from './UserApps';
import UserDetails from './UserDetails';
import Button from '@material-ui/core/Button';
import { Axios } from '../../../Utils';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import { FaAngleLeft, FaCheck } from 'react-icons/fa';
import OtherToolbar from '../../Layout/OtherToolbar/OtherToolbar';
import Content from '../../Layout/Content/Content';

const config = Config[process.env.NODE_ENV];

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    toolbar: { },
    button: {
        margin: theme.spacing.unit,
        textDecoration: 'none',
        background: 'white'
    },

});

const TabContainer = (props) => {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

class User extends React.Component {

    state = {
        user: null,
        apps: null,
        dataLoaded: false,
        token: null,
        defaultTab: 0,
        dialog: { open: false, confirmed: false },
        valid: false
    }

    handleTabChange = (event, defaultTab) => {
        this.setState({ defaultTab });
    };

    appsInstance = null;
    userInstance = null;

    componentWillMount() {
        let token = localStorage.getItem(config.NAMESPACE) ? JSON.parse(localStorage.getItem(config.NAMESPACE)).token : null;
        this.setState({ token: token });
    }

    // componentWillReceiveProps( newProps) {
    //     if ( newProps !== this.props) {
    //         this.setState(newProps)
    //     }
    // }
    async componentDidMount() {
        let user = null, apps = null;
        let id = this.props.match.params.id;

        try {

            apps = await Axios({
                method: 'POST',
                url: '/api/v1/find',
                data: { table: 'apps' },
            })

            user = await Axios({
                method: 'POST',
                url: `/api/v1/find`,
                data: { table: 'users', data: { where: { id: id } } },
            })
        } catch (err) { console.log("sequelize error", err) }
        finally {

            user = user.data[0];
            user.apps = JSON.parse(user.apps)
            apps = apps.data

            if (apps && apps.length > 0) {
                apps.map((e, i) => {
                    apps[i]['allowed_roles'] = e.allowed_roles.length > 0 ? JSON.parse(e.allowed_roles) : []
                    return true;

                })
            } else { apps = [] }

            if (typeof id === 'undefined') {
                this.setState({ apps: apps, user: this.props.user, dataLoaded: true })
            } else {
                this.setState({
                    apps: apps,
                    user: user,
                    dataLoaded: true
                })
            }
            // console.log("state of user ", this.state)

        }

    }

    userDetailsHandler = name => event => {
        console.log(name, event.target.value)
        let user = this.state.user;
        user[name] = event.target.value;
        this.setState({ user: user })
    }

    confirmHandler = () => {
        this.setState({ dialog: { open: true } });
    }

    okHandler = async () => {
        let res = []
        let data = { ...this.state.user };
        if (data.password === '') { delete (data.password); }

        data.apps = JSON.stringify(data.apps)
        try {
            res.push(await Axios({
                method: 'PATCH',
                url: '/api/v1/update',
                data: { table: 'users', data: data }
            }))
        } catch (err) { console.log("update error:", err) }
        finally {
            console.log("updated!")
            this.props.history.push('/users')
        }
    }

    render() {
        // console.log("U S E R   S T A T E: \n", this.state)
        const { classes } = this.props;
        const { defaultTab } = this.state;
        return (
            this.state.dataLoaded ? (
                <div>
                    <Dialog open={this.state.dialog.open} onClose={this.state.dialog.rejectAction ? this.state.dialog.rejectAction : null}>

                        <DialogTitle> {this.state.dialog.title ? this.state.dialog.title : 'ATENȚIONARE'}</DialogTitle>
                        <DialogContentText> {this.state.dialog.content ? this.state.dialog.content : 'Operațiunea va fi efectuată! Confirmați?'} </DialogContentText>
                        <DialogActions>
                            <Button onClick={() => this.setState({ dialog: { confirmed: false, open: false } })}>NU</Button>
                            <Button onClick={this.okHandler} > DA </Button>
                        </DialogActions>
                    </Dialog>
                    <OtherToolbar>
                        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.toolbar}>

                            <Button variant="fab" color="primary" mini>
                                <FaAngleLeft style={{ fontSize: '2em' }} onClick={() => { this.props.history.push('/users') }} />
                            </Button>

                            <Grid item>

                                <Typography variant="h6">
                                    &nbsp;&nbsp;
                                    <Hidden only={["lg", "md", "sm"]}>
                                         <strong>{this.state.user.name.length < 15 ? this.state.user.name : this.state.user.name.slice(0,15)+ '...'}</strong>
                                    </Hidden>
                                    <Hidden only={["xs"]}>
                                         <strong>{this.state.user.name}</strong>
                                    </Hidden>
                                </Typography>

                            </Grid>
                            <Grid item>

                                <Button
                                    disabled={!(this.state.user.name && this.state.user.username && this.state.user.email)}
                                    variant="fab" color="primary" mini
                                    onClick={this.state.user.id ? this.confirmHandler : this.props.confirmHandler(this.state.user)} >
                                    <FaCheck style={{ fontSize: '2em' }} />
                                </Button>
                            </Grid>
                        </Grid>
                    </OtherToolbar>

                    <Content>
                        <Tabs value={defaultTab} onChange={this.handleTabChange}>
                            <Tab label="DATE UTILIZATOR" />
                            <Tab label="APLICAȚII ȘI ROLURI" />

                        </Tabs>

                        {defaultTab === 0 && <TabContainer><UserDetails {...this.state} userDetailsHandler={this.userDetailsHandler} /></TabContainer>}
                        {defaultTab === 1 && <TabContainer><UserApps {...this.state} /></TabContainer>}
                    </Content>
                </div>

            )
                : null

        )
    }
}

export default withRouter(withStyles(styles)(User));