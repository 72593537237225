import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

const styles = {
    menuButton: {
        marginLeft: -12,
        marginRight: 10,
        color: 'white',
        textDecoration: 'none'
    },
    link: {
        textDecoration: 'none'
    }
};

class Hamburger extends React.Component {

    state = {
        anchorEl: null,
        menuItems: [
            { id: 1, label: "Meniul principal", url: "/landing" },
            { id: 2, label: "Gestiune utilizatori", url: "/users" },
            { id: 3, label: "Configurare aplicații", url: "/apps" },
        ]
    };

    handleClick = event => this.setState({ anchorEl: event.currentTarget });
    handleClose = () => { this.setState({ anchorEl: null }) };

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;

        return (
            <div>
                <Typography/>
                <IconButton
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    className={classes.menuButton}
                    onClick={this.handleClick} >
                    <MenuIcon />
                </IconButton>

                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    {this.state.menuItems.map(el => (
                        <div key = {el.id}>
                        <Link to={el.url} className = {classes.link}>
                            <MenuItem key={el.id}  onClick={this.handleClose}>{el.label}</MenuItem>
                        </Link>

                        </div>
                    ))}

                </Menu>
            </div>
        )
    }

}

export default withStyles(styles)(Hamburger);