import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../../assets/svg/weblogin.svg';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';
import Content from '../Layout/Content/Content';
const styles = theme => ({
    root:{
        // padding: theme.spacing.unit,
        marginTop: '-64px' 
    },
  
    paper: { 
        padding: theme.spacing.unit,
        margin: theme.spacing.unit,
        height: 'auto'
    },
    gridItem: {
        padding: theme.spacing.unit,
    },
    button: {
        minHeight: theme.spacing.unit*30,
        minWidth: theme.spacing.unit*30,
        fontSize:"2em",
        background: '#ffffff'
       
    },
    link: {
        textDecoration: 'none',
        
    }
})

const Landing = (props) => {
    const { classes } = props;
        return (
            <Content>
            <Grid container direction="row" justify="space-evenly" alignItems="center" alignContent ="center" className = {classes.root}>
                <Grid item className = {classes.gridItem}>
                    <Link to = "/users" className = {classes.link}>
                    <Button variant = "outlined" color = "primary" size="large" className = {classes.button}>
                        <Typography variant = "h4" color ="primary">UTILIZATORI</Typography>
                    </Button>
                    </Link>
                </Grid>
                <Hidden only = "xs">
                <Grid item lg={4} md = {4}>
                    
                    <Paper className = {classes.paper}>
                        <img src = {Logo} alt = "application logo"/>
                    </Paper>
                </Grid>
</Hidden>
                <Grid item className = {classes.gridItem}>
                    <Link to = "/apps" className = {classes.link}>
                         <Button variant = "outlined" color = "primary" size="large" className = {classes.button}>
                              <Typography variant = "h4" color ="primary">APLICAȚII</Typography>
                        </Button>
                    </Link>
                </Grid>                

            </Grid>
            </Content>
        )
}

export default withRouter(withStyles(styles)(Landing))