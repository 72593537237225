import React from 'react';
import { Link } from 'react-router-dom';
import Codes from './codes';
import CssBaseline from "@material-ui/core/CssBaseline";
import Button  from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withRouter } from 'react-router-dom';

let code = null;
const Error = (props) => {
    console.log("error props:", props)
    props = {...props}

     code = Codes.filter(e => e.code === props.status)
       
    let  msg = props.msg ? props.msg : 'nici o informatie suplimentara ';
    // props.match.params.msg ? msg = props.match.params.msg : null;
    return (
        <React.Fragment>
            <br />
            <CssBaseline /> 
            <Grid container direction="row" justify="center" alignItems="center" >
                <Grid item xs={10}>

                    <Paper style={{ padding: '50px' }}>

                        <h1><strong>{code[0].code}</strong> - {code[0].short}</h1>
                        <h3>{code[0].long}</h3>
                        <h4 style = {{color:'grey'}}><i>{msg}</i></h4>
                        <Grid container direction="row" alignItems="flex-end">
                            <Link to='/' style = {{textDecoration:'none'}}>
                                <Button variant="contained" color="secondary">INAPOI</Button>
                            </Link>
                        </Grid>
                    </Paper>

                </Grid>
            </Grid >
        </React.Fragment>
    )
}

export default withRouter(Error);