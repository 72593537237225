const Codes = [
    {
        "id": 1,
        "code": 200,
        "short": "OK",
        "long": "The request is OK (this is the standard response for successful HTTP requests)"},
    {
        "id": 2,
        "code": 201,
        "short": "Created",
        "long": "The request has been fulfilled, and a new resource is created"},
    {
        "id": 3,
        "code": 202,
        "short": "Accepted",
        "long": "The request has been accepted for processing, but the processing has not been completed"},
    {
        "id": 4,
        "code": 203,
        "short": "Non-Authoritative Information",
        "long": "The request has been successfully processed, but is returning information that may be from another source" },
    {
        "id": 5,
        "code": 204,
        "short": "No Content",
        "long": "The request has been successfully processed, but is not returning any content"},
    {
        "id": 6,
        "code": 205,
        "short": "Reset Content",
        "long": "The request has been successfully processed, but is not returning any content, and requires that the requester reset the document view" },
    {
        "id": 7,
        "code": 206,
        "short": "Partial Content",
        "long": "The server is delivering only part of the resource due to a range header sent by the client" },
    {
        "id": 8,
        "code": 300,
        "short": "Multiple Choices",
        "long": "A link list. The user can select a link and go to that location. Maximum five addresses �" },
    {
        "id": 9,
        "code": 301,
        "short": "Moved Permanently",
        "long": "The requested page has moved to a new URL�" },
    {
        "id": 10,
        "code": 302,
        "short": "Found",
        "long": "The requested page has moved temporarily to a new URL�" },
    {
        "id": 11,
        "code": 303,
        "short": "See Other",
        "long": "The requested page can be found under a different URL" },
    {
        "id": 12,
        "code": 304,
        "short": "Not Modified",
        "long": "Indicates the requested page has not been modified since last requested" },
    {
        "id": 13,
        "code": 306,
        "short": "Switch Proxy",
        "long": "No longer used" },
    {
        "id": 14,
        "code": 307,
        "short": "Temporary Redirect",
        "long": "The requested page has moved temporarily to a new URL" },
    {
        "id": 15,
        "code": 308,
        "short": "Resume Incomplete",
        "long": "Used in the resumable requests proposal to resume aborted PUT or POST requests" },
    {
        "id": 16,
        "code": 400,
        "short": "Bad Request",
        "long": "The request cannot be fulfilled due to bad syntax" },
    {
        "id": 17,
        "code": 401,
        "short": "Unauthorized",
        "long": "The request was a legal request, but the server is refusing to respond to it. For use when authentication is possible but has failed or not yet been provided" },
    {
        "id": 18,
        "code": 402,
        "short": "Payment Required",
        "long": "Reserved for future use" },
    {
        "id": 19,
        "code": 403,
        "short": "Forbidden",
        "long": "The request was a legal request, but the server is refusing to respond to it" },
    {
        "id": 20,
        "code": 404,
        "short": "Not Found",
        "long": "The requested page could not be found but may be available again in the future" },
    {
        "id": 21,
        "code": 405,
        "short": "Method Not Allowed",
        "long": "A request was made of a page using a request method not supported by that page" },
    {
        "id": 22,
        "code": 406,
        "short": "Not Acceptable",
        "long": "The server can only generate a response that is not accepted by the client" },
    {
        "id": 23,
        "code": 407,
        "short": "Proxy Authentication Required",
        "long": "The client must first authenticate itself with the proxy" },
    {
        "id": 24,
        "code": 408,
        "short": "Request Timeout",
        "long": "The server timed out waiting for the request" },
    {
        "id": 25,
        "code": 409,
        "short": "Conflict",
        "long": "The request could not be completed because of a conflict in the request" },
    {
        "id": 26,
        "code": 410,
        "short": "Gone",
        "long": "The requested page is no longer available" },
    {
        "id": 27,
        "code": 411,
        "short": "Length Required",
        "long": "The \"Content-Length\" is not defined. The server will not accept the request without it�" },
    {
        "id": 28,
        "code": 412,
        "short": "Precondition Failed",
        "long": "The precondition given in the request evaluated to false by the server" },
    {
        "id": 29,
        "code": 413,
        "short": "Request Entity Too Large",
        "long": "The server will not accept the request, because the request entity is too large" },
    {
        "id": 30,
        "code": 414,
        "short": "Request-URI Too Long",
        "long": "The server will not accept the request, because the URL is too long. Occurs when you convert a POST request to a GET request with a long query information�" },
    {
        "id": 31,
        "code": 415,
        "short": "Unsupported Media Type",
        "long": "The server will not accept the request, because the media type is not supported�" },
    {
        "id": 32,
        "code": 416,
        "short": "Requested Range Not Satisfiable",
        "long": "The client has asked for a portion of the file, but the server cannot supply that portion" },
    {
        "id": 33,
        "code": 417,
        "short": "Expectation Failed",
        "long": "The server cannot meet the requirements of the Expect request-header field" },
    {
        "id": 34,
        "code": 500,
        "short": "Internal Server Error",
        "long": "A generic error message, given when no more specific message is suitable" },
    {
        "id": 35,
        "code": 501,
        "short": "Not Implemented",
        "long": "The server either does not recognize the request method, or it lacks the ability to fulfill the request" },
    {
        "id": 36,
        "code": 502,
        "short": "Bad Gateway",
        "long": "The server was acting as a gateway or proxy and received an invalid response from the upstream server" },
    {
        "id": 37,
        "code": 503,
        "short": "Service Unavailable",
        "long": "The server is currently unavailable (overloaded or down)" },
    {
        "id": 38,
        "code": 504,
        "short": "Gateway Timeout",
        "long": "The server was acting as a gateway or proxy and did not receive a timely response from the upstream server" },
    {
        "id": 39,
        "code": 505,
        "short": "HTTP Version Not Supported",
        "long": "The server does not support the HTTP protocol version used in the request" },
    {
        "id": 40,
        "code": 511,
        "short": "Network Authentication Required",
        "long": "The client needs to authenticate to gain network access" }];

export default Codes;