import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Axios } from '../../../Utils';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { FaAngleLeft, FaCheck, FaPlusCircle } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import OtherToolbar from '../../Layout/OtherToolbar/OtherToolbar';
import Content from '../../Layout/Content/Content';

const styles = theme => (
    {
        root: {},
        paper: {
            margin: theme.spacing.unit*2,
            padding: theme.spacing.unit*1
        },
        toolbar: { },
        input: { width: '100%'},
        gridItem: { padding: theme.spacing.unit*1.2},
        chip: {  margin: theme.spacing.unit, },

    }
)

class AppAdd extends React.Component {

    state = { 
        dataLoaded: false, 
        err: null, 
        app: {
            code: '',
            name: '',
            url: '', 
            desc:'',
            allowed_roles: [] 

        },
        newApp: '',
        dialog: { open: false, confirmed: false },
    }

    async componentDidMount() {

        let response = null;
        try {
            response = await Axios({
                method: 'POST',
                url: '/api/v1/find',
                data: { table: 'apps' }
            })
        }
        catch (err) {
            console.log("error:", err);
            this.setState({ error: true, msg: err.message, status: 401 })
        }
        finally {
                let apps = response.data;
                if ( apps.length > 0 ) {
                    apps.map( (e,i) => {
                        apps[i].allowed_roles = JSON.parse(e.allowed_roles)
                    })
                }

                this.setState( { apps: apps, dataLoaded: true } );
            
        }
    }

    changeHandler = name => event => {
        console.log(name,event.target.value)
        let app = this.state.app;
        app[name] = event.target.value;
        this.setState( {app: app} )
    }

    appInputHandler = event  => {
        console.log(event.target.value)
        this.setState({newApp: event.target.value})
    }

    appAddHandler =  () => {
        let app = this.state.app;
        app.allowed_roles.push(this.state.newApp)
        this.setState( {app:app, newApp:''} )
    }

    appDeleteHandler  = event => () => {
        console.log(event)
        let app = this.state.app;
        app.allowed_roles.splice(app.allowed_roles.indexOf(event))
        this.setState( {app:app} )
    }
    
    confirmHandler = event => () => {
        this.setState({dialog:{open:true, confirm:false}})
     }

    okHandler = async () =>{
        let result = null
        let data = {...this.state.app}
        data.allowed_roles = JSON.stringify(data.allowed_roles);
        try { 
            result = Axios({
                method: 'PUT',
                url: 'api/v1/create',
                data: { table: 'apps', data: data}
            })
        } catch(err) { console.log("app update error:", err ) }
        finally {
            this.setState({dialog: {open:false}})
            this.props.history.push('/apps')
        }

    }

    render() {
        console.log("state", this.state)
        const { classes } = this.props;
        return (
            <div>
                {this.state.dataLoaded
                    ?
                    <Grid container direction="column">
                        <Dialog open={this.state.dialog.open}
                            disableBackdropClick={false}
                            onBackdropClick = {()=> this.setState({dialog:{open: false}})}
                            size="small"
                            onClose={this.state.dialog.rejectAction ? this.state.dialog.rejectAction : null}>

                            <DialogTitle> {this.state.dialog.title ? this.state.dialog.title : 'ATENȚIONARE'}</DialogTitle>
                            <DialogContent>
                                <DialogContentText> {this.state.dialog.content ? this.state.dialog.content : 'Operațiunea va fi efectuată! Confirmați?'} </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ dialog: { confirmed: false, open: false } })}>NU</Button>
                                <Button onClick={this.okHandler} > DA </Button>
                            </DialogActions>
                        </Dialog>    
                        <OtherToolbar>                
                            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.toolbar}>

                                <Typography variant="h5" color="primary">
                                    <Button variant="fab" color="primary" mini>
                                        <FaAngleLeft style={{ fontSize: '2em' }} onClick={() => { this.props.history.push('/apps') }} />
                                    </Button>
                                </Typography>

                                <Grid item>
                                    <Typography variant="h6" color = "primary">
                                        &nbsp;&nbsp;
                                        <strong> ADAUG APLICAȚIE</strong>
                                    </Typography>

                                </Grid>
                                <Grid item>
                                {/* confirm button */}
                                    <Button
                                        disabled={!(this.state.app.name && this.state.app.code && this.state.app.url )}
                                        variant="fab" color="primary" mini
                                        onClick={this.confirmHandler(this.state.app)} >
                                        <FaCheck style={{ fontSize: '2em' }} /> 
                                    </Button>
                                </Grid>
                            </Grid>
                        </OtherToolbar>

                        {/* <Grid item>
                            <Paper className={classes.paper}>
                                <pre> <small> {JSON.stringify(this.state.app, null, 4)} </small></pre>
                            </Paper>
                        </Grid> */}
                        <Content>
                            <Paper className={classes.paper}>
                            <Grid container direction="row" justify="space-around" alignItems="stretch">
                                <Grid item xs={4} md={2} lg = {1}  className = {classes.gridItem}>
                                    <TextField variant="outlined" className = {classes.input} value={this.state.app.code} label = "cod" onChange = {this.changeHandler('code')} />
                                </Grid>

                                <Grid item xs={8} md = {4} lg = {5} className = {classes.gridItem}>
                                    <TextField variant="outlined"  className = {classes.input} size="small" value={this.state.app.name} label = "denumire"  onChange = {this.changeHandler('name')} />
                                </Grid>

                                <Grid item xs={12} md = {6} lg = {6} className = {classes.gridItem}>
                                    <TextField variant="outlined"  className = {classes.input} size="small" value={this.state.app.url} label = "login url"  onChange = {this.changeHandler('url')} />
                                </Grid>    
                                <Grid item xs={12}  className = {classes.gridItem}>
                                    <TextField variant="outlined"  className = {classes.input} size="small" value={this.state.app.desc} label = "descriere"  onChange = {this.changeHandler('desc')} />
                                </Grid> 
                                <Grid item xs={9} md = {11} lg = {11} className = {classes.gridItem}>
                                    <TextField variant="outlined"  className = {classes.input} size="small" value = {this.state.newApp} label = "defineste un rol nou" onChange = {this.appInputHandler} />
                                </Grid>          
                                <Grid item xs={3} md={1} lg = {1} className = {classes.gridItem}>
                                    { this.state.newApp.length>0
                                    ? <Button ><Typography color="primary"><FaPlusCircle style = {{ fontSize:'2.7em'}} onClick = {this.appAddHandler} /> </Typography> </Button>
                                    : null}
                                    
                                </Grid> 

                                <Grid item xs = {12} md = {12} lg = {12}> 
                                    {this.state.app.allowed_roles.map( (e,i) => (
                                        <Chip
                                            key = {'role-'+i}
                                            label={e}
                                            onDelete={this.appDeleteHandler(e)}
                                            className={classes.chip}
                                            color = "primary"
                                            variant = "outlined"
                                        />
                                    ))}

                                </Grid>                                                                         
                            </Grid>
                            </Paper>
                        </Content>
                    </Grid>

                    : null
                }

            </div>
        )
    }
}

export default withRouter(withStyles(styles)(AppAdd));