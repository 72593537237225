import axios from 'axios';
import Config from '../../Config';

const Axios = () => {

    const conf = Config[process.env.NODE_ENV];
   
    const defaultOptions = {
        baseURL: conf.API_PATH,
        headers: {
          'Content-Type': 'application/json',
        },
      };

    let instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (config) {
    
        const ls = localStorage.getItem(conf.NAMESPACE) ? JSON.parse(localStorage.getItem(conf.NAMESPACE)) : null;
        config.headers['x-auth-token'] =  ls.token ? ls.token : '';
        config.headers['APP'] = conf.APP_NAME;
        return config;

    });
  return instance;
}

export default Axios();