import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Hamburger from '../Hamburger/Hamburger';
import Hidden from '@material-ui/core/Hidden';

import Config from '../../../Config';
const config = Config[process.env.NODE_ENV];

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    height: '64px', maxHeight: '64px',width: '100vw'
  }

};

class ToolbarTop extends React.Component {

  state =  {anchorEl: null, user: JSON.parse(localStorage.getItem(config.NAMESPACE))}

  logoutHandler = () => {
    localStorage.removeItem(config.NAMESPACE);
    window.location= '/'
  }

  render() {
    console.log("TOOLBAR STATE:", this.state)

    const { classes } = this.props;
    return (
      <div className={classes.root}>

          <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Hamburger/>

            <Typography variant="h6" color="inherit" >
              WEBLOGIN
              </Typography>
            <div className={classes.grow}></div>
            <Hidden only ="xs">
              <IconButton color="inherit"><AccountCircle /></IconButton>
            </Hidden>
            
            <Typography variant = "caption" color="inherit">{this.state.user.name.length < 15 ? this.state.user.name : this.state.user.name.slice(0,15)+'...'}
              <span style={{ textTransform: "none" }}><small> &nbsp; <Hidden only ="xs">({this.state.user.email}) </Hidden> </small></span>
            </Typography>
            <IconButton color="inherit" onClick = {this.logoutHandler}><PowerSettingsNew /></IconButton>

          </Toolbar>
        </AppBar>

      </div>
    );
  }

}

ToolbarTop.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ToolbarTop);