import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  main: {background:'#ffffff', marginTop:'128px'}

}

const  Content = (props) => {

    const { classes } = props;

  return (

      <div >
            <Grid className={classes.main}
                container
                direction="column"
                alignItems="stretch">
                  
                        {props.children}
                    
            </Grid>
      </div>
    
  )

}

export default withStyles(styles)(Content);