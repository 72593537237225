import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Axios } from '../../../Utils';
import User from './User';

const styles = {
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
};

class AddUser extends React.Component {

    state = {
      dataLoaded: true,
      open:true,
      confirmed: false,
      user: {
        apps: [],
        email: "",
        facebook_id: "",
        google_id: "",
        name: "",
        username: "",
        password:""
      }
    }

    confirmHandler = data => async () => {
      data = {...data}
      data.apps = JSON.stringify(data.apps)

      console.log("PUT DATA:", data)
      let result = null;
      try {
        result = await Axios({
          method: 'PUT', 
          url:'api/v1/create',
          data: {table: 'users', data:data}
        })
      } catch(err) { console.log("insert error:",err)}
      finally {
        console.log("rezult----------------------",result.data.data);
         this.props.history.push( "/user/"+result.data.data )
      }
    }

    render() {
        return (
          <div>
          {this.state.open && this.state.dataLoaded
            ? <User user = {{...this.state.user, password:''}} dataLoaded = {true} confirmHandler = {this.confirmHandler} apps = {this.state.apps} />
            : null
          }
          </div>

        )
    }
}

export default (withStyles(styles)(AddUser));
