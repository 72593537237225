import React, { Component } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import { isLoggedIn } from './Utils';
import Config from './Config';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Routes from './Routes';
import Layout from './components/Layout/Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Landing from './components/Landing/Landing'

const config = Config[process.env.NODE_ENV]

const styles = theme => ({
  root: { flexGrow: 1 },
  paper: { padding: theme.spacing.unit, textAlign: 'center' },
  link: { textDecoration: 'none' }
})

class App extends Component {

  state = { l: false, isAuthOk: false }

  async componentWillMount() {
    let res = null;

    // console.log("token", token)
    try {
      res = await isLoggedIn()
    } catch (err) { console.log(err) }
    finally {
      if (!res) {
        window.location.replace(config.AUTH_PATH);
      } else {
        //  console.log("res in APP:", res)
        if (res.data.id) {
          this.setState({ l: true, isAuthOk: true })
        } else {
          this.setState({ l: true, isAuthOk: false })
        }

      }
    }

  }

  render() {

    const { classes } = this.props;
    // console.log(this.props)
    return (
      <div>
        {this.state.l
          ? (
            <div>

              {this.props.location.pathname === '/' && !!this.state.isAuth ? <Landing/> : null }

              {this.state.isAuthOk
                ?
                <CssBaseline>
                  <Layout>
                    <Switch>
                      <Routes />
                    </Switch>
                  </Layout>
                </CssBaseline>
                : <div>
                  <Grid container direction="column" justify="center" style={{ minHeight: '100vh' }}>
                    <Grid container direction="row" justify="center" alignItems="stretch">
                      <Grid item xs={11} sm={8} lg={4}>
                        <Paper className={classes.paper}>
                          <Typography variant="h5" color="primary">
                            Nu am putut valida cheia de acces! Nu ești autentificat? <br /><br />
                            <a href={config.AUTH_PATH} className={classes.link}>
                              <Button variant="contained" color="secondary" size="large" style={{ fontWeight: 800, minHeight: '10vh' }}>
                                <h1>AUTENTIFICARE</h1>
                              </Button>
                            </a>

                          </Typography>
                          <br />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
            </div>
          ) :
          <Grid container direction="column" justify="center" style={{ minHeight: '100vh' }}>
            <Grid container direction="row" justify="center" alignItems="stretch">
              <Grid item xs={11} sm={8} lg={4}>
              </Grid>
              {/* <Paper className={classes.paper}> */}
                <Typography variant="h6" color="primary">
                  verificare token...
                </Typography>
              {/* </Paper> */}
            </Grid>
          </Grid>

        }
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(App));
