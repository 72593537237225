
const Config = {

        production: {
            APP_NAME        : "ADMIN",
            API_PATH        : "https://api.weblogin.ro",
            AUTH_PATH       : "https://weblogin.ro",
            NAMESPACE       : 'weblogin.ro'
        },
        development: {
            APP_NAME        : "ADMIN-LOCAL",
            API_PATH        : "http://localhost:3200",
            AUTH_PATH       : "http://localhost:3200", 
            NAMESPACE       : 'weblogin.ro'
        },

};

export default Config;