
import Config from '../../Config';
import axios from 'axios';
const  config = Config[process.env.NODE_ENV];

const isLoggedIn = async (props) => {
    let token = null;

    if ( props ) { 
        token = props;
    } else {
        let ls = localStorage.getItem(`${config.NAMESPACE}`);
        if ( ls ) {
            ls = JSON.parse(ls)
            token = ls.token;
        } else {
            token = '';
        }
    }
    let response = null;
    try {
        response = await axios({
            method:     'POST', 
            url:        config.API_PATH + '/api/v1/is-token-valid',
            headers:    { 'x-auth-token' : token }
        })
    
    } catch(err) { console.log(err); return err }
    finally {
        // console.log(response)
        return response;
    }
} 

export default isLoggedIn;