import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Layout from '../../../components/Layout/Layout';
import { Axios } from '../../../Utils';
import Config from '../../../Config';
import Error from '../../../components/Error/Error';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { FaAngleLeft, FaPlus } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogTitle';
import OtherToolbar from '../../Layout/OtherToolbar/OtherToolbar';
import Content from '../../Layout/Content/Content';

const config = Config[process.env.NODE_ENV]

let response = null;

const styles = theme => ({
    root: { flexGrow: 1 },
    paper: { margin: theme.spacing.unit * 0.5, padding: theme.spacing.unit * 1.5, width: '100%' },
    toolbar: { background: 'lightblue' }
})

class Apps extends React.Component {

    state = {
        apps: null,
        error: false,
        status: 200,
        msg: null,
        l: false,
        dialog: { open: false, confirmed: false, event: null },
    }

    async componentDidMount() {

        try {
            response = await Axios({
                method: 'POST',
                url: config.API_PATH + '/api/v1/find',
                data: { table: 'apps' },
            })
        }
        catch (err) {
            console.log("error:", err);
            this.setState({ error: true, msg: err.message, status: 401 })
        }
        finally {

            if (response && response.data) {
                this.setState({ apps: response.data, l: true })
            } else {
                this.setState({ error: true, msg: 'Interogarea sql a esuat, probabil rolul Dvs. nu permite accesul', status: 401 })
            }

        }

    }

    userSelectHandler = (props) => {
        this.props.history.push({
            pathname: '/admin-app/app/' + props.id,
            data: { ...props }
        })

    }

    removeHandler = name => {
        this.setState({ dialog: { open: true, confirmed: false, event: name } })
    }

    okHandler = async () => {
        let response = null;
        let apps = this.state.apps;
        try {
            Axios({
                method:'POST', 
                url: 'api/v1/remove',
                data: { table: 'apps', id: this.state.dialog.event.id }
            })
        } catch (err) { console.log("delete app error: ", err) }
        finally {
            apps.splice(apps.indexOf(this.state.dialog.event),1)
            this.setState( {apps: apps, dialog: {open: false }} )
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {!!this.state.error ? <Error {...{ status: 401, msg: this.state.msg }} /> : null}
                {!!this.state.l
                    ? <div>
                        <Dialog open={this.state.dialog.open}
                            disableBackdropClick={false}
                            onBackdropClick={() => this.setState({ dialog: { open: false } })}
                            size="small"
                            onClose={this.state.dialog.rejectAction ? this.state.dialog.rejectAction : null}>

                            <DialogTitle> {this.state.dialog.title ? this.state.dialog.title : 'ATENȚIONARE'}</DialogTitle>
                            <DialogContent>
                                <DialogContentText> {this.state.dialog.content ? this.state.dialog.content : 'Operațiunea va fi efectuată! Confirmați?'} </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ dialog: { confirmed: false, open: false } })}>NU</Button>
                                <Button onClick={this.okHandler} > DA </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container direction="column" justify="space-between" alignItems="stretch">
                            <OtherToolbar>
                            <Grid container direction="row" justify="space-between" alignItems="stretch" className={classes.toolbar}>

                                <Grid item >
                                    <Button
                                        variant="fab"
                                        color="primary" mini
                                        onClick={() => { this.props.history.push('/landing') }} >
                                        <FaAngleLeft style={{ fontSize: '2em' }} />
                                    </Button>

                                    {/* </Grid> */}
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="primary"><strong>  APLICAȚII </strong></Typography>
                                </Grid>

                                <Button
                                    variant="fab"
                                    color="primary" mini
                                    onClick={() => this.props.history.push('/app-add')}>
                                    <FaPlus style={{ fontSize: '2em' }} />
                                </Button>
                            </Grid>
                            </OtherToolbar>

                            <Content>
                            {/* <pre>{JSON.stringify(this.state.apps, null,4)}</pre> */}
                            {this.state.apps.map((e, i) => (
                                <Grid item key={'app-' + i} >
                                    <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                                        <Paper
                                            className={classes.paper}
                                            onClick={() => this.props.history.push(`/app/${e.id}`)}
                                            onContextMenu={event => { event.preventDefault(); this.removeHandler(e) }}
                                        >
                                            <div>
                                                Cod: <strong> {e.code} </strong> &nbsp;
                                                        Denumire: <strong> {e.name} </strong>
                                                <br />
                                                Descriere: <strong> {e.desc} </strong>
                                                <br />
                                                Adresa URL: <strong> {e.url} </strong>
                                                <br />
                                                Roluri alocate: &nbsp;
                                                            {JSON.parse(e.allowed_roles).map((role, irole) => (
                                                    <span key={irole + role}>
                                                        <strong>{role}</strong>
                                                        {/* {irole} == {e.allowed_roles.length-1 } */}
                                                        {irole === JSON.parse(e.allowed_roles).length - 1 ? ' ' : ', '}
                                                    </span>
                                                ))}
                                            </div>
                                        </Paper>
                                    </Grid>

                                </Grid>
                               
                            ))}
                             </Content>
                        </Grid>
                    
                    </div>

                    : <p>incarc rezultatele...</p>}
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Apps));