import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
 import  moment from 'moment';
 import Paper from '@material-ui/core/Paper';

const styles = theme => {
        // console.log("theme", theme)
    return ({
    root: { flexGrow: 1,  },
    gridItem:  {border:'1px dotted navy',  padding:theme.spacing.unit*0.2},
    gridItemTop:  {border:'1px dotted navy',  padding:theme.spacing.unit, background: theme.palette.background.default},
    checkbox: {},
    button: {},
    toolbarButtons: {},
    datePicker: { width: theme.spacing.unit*30, margin: theme.spacing.unit, padding: 0},
    paper: {margin: theme.spacing.unit}
})}

class UserApps extends React.Component {

    state = { apps: null, data:null,  dataLoaded: false, now: moment(new Date()).format('YYYY-MM-DD') }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked })
    }

    componentDidMount() {
        let result = this.props;
        this.setState({ ...result, dataLoaded: true })
    }

    roleCheckerHandler = (app, role, event)  => {
        console.log("app:", app);
        console.log("role:", role);
        console.log("event", event);

        let checked = event.target.checked;
        let user = this.state.user;
        let targetApp = this.state.user.apps.filter( elem => elem.name === app.name );
        console.log("ta:", targetApp)
        if ( !!checked ) {
            if ( targetApp.length === 0 ) { 
                user.apps.push({name: app.name, role: role, expiry: this.state.now})
            } else {
                user.apps.splice(user.apps.indexOf(targetApp[0]), 1)
                user.apps.push({name: app.name, role: role, expiry: this.state.now})
            }
        } else {
            //if unchecked
            user.apps.splice(user.apps.indexOf(targetApp[0]), 1)
        }

        this.setState({user:user})
        console.log("state after update.....", this.state)
    }

    appCheckerHandler = (app) => {
        let user = this.state.user;
        user.apps.splice(user.apps.indexOf(app),1)
        this.setState({user:user})  
    }
    datePicker = app =>  event => {
       let user = this.state.user;
       let newDate = event.target.value;
       let currentAppIndex = user.apps.indexOf( (user.apps.filter( e => e.name === app)[0]) );
       console.log("current app index:", currentAppIndex)
       user.apps[currentAppIndex]['expiry'] = moment(newDate).format('YYYY-MM-DD');
       this.setState( {user: user} )
       console.log("----user-----:", user);
       console.log("----app-----:", app);
       console.log("----newDate-----:",newDate);
       
    }

    render() {
    if (this.state.dataLoaded) {
        console.log("USER DETAILS STATE:", this.state) 
        // console.log("apps.allowed_roles", this.state.apps[0].allowed_roles)
    }
        const { classes } = this.props;

        return (
            
            <Grid container direction = "column" justify = "center" alignItems="stretch">
                <Paper className = { classes.paper } >
                     <Grid container direction = "row" justify = "space-between" alignItems="stretch">
                        
                            <Grid item xs = {5} className = {classes.gridItemTop} > <strong> APLICAȚIE </strong></Grid>
                            <Grid item   xs = {7} className = {classes.gridItemTop} > <strong> ROLURI ACTIVE </strong></Grid>
                           
                    </Grid>
                </Paper>
                
            {this.state.dataLoaded 
                ? this.state.apps.map(e => {
                    return (
                        <Paper className = { classes.paper }  key = {e.id} >
                             <Grid  container direction = "row" justify = "space-between"  alignItems="stretch" >
                           
                                <Grid item  xs={5} className = {classes.gridItem} > 
                                    <div>
                                        <Checkbox 
                                            className = {classes.checkbox}

                                            checked={ this.state.user.apps.filter( elem => elem.name === e.name ).length > 0 ? true:false} 
                                            disabled = { this.state.user.apps.filter( elem => elem.name === e.name ).length > 0 ? false:true} 
                                            
                                            onChange={()=>this.appCheckerHandler(e.name)} />{e.name}
                                    </div>

                                </Grid>
                                <Grid item xs={7}  className = {classes.gridItem}>

                                        {   e.allowed_roles.map( (role, index)  => (
                                            
                                            <div key = {'role-'+index}>

                                                <Checkbox 
                                                onChange = {(event) => this.roleCheckerHandler(e, role, event)  }
                                                checked = { this.state.user.apps.filter( el => el.name === e.name && el.role === role ).length > 0 } />
                                                {role}
                                                </div>
                                                
                                            ))
                                        }
                                </Grid>
                                <Grid item xs = {12}  className = {classes.gridItem}>
                                    {this.state.user.apps.filter( elem => elem.name === e.name).length > 0 
                                        ? <div>
                                            <TextField 
                                                className = { classes.datePicker }
                                                type = "date"
                                                variant = "outlined"
                                                label  = 
                                                {
                                                    moment(  (this.state.user.apps.filter( elem => elem.name === e.name))[0].expiry  ).diff( moment(new Date()), 'days') >= 0
                                                    ? 
                                                        <small> expiră în <strong>
                                                    {moment(  (this.state.user.apps.filter( elem => elem.name === e.name))[0].expiry  ).diff( moment(new Date()), 'days') +1 }
                                                    </strong> zile </small>
                                                    : <small style = {{color: 'red'}}><strong> EXPIRAT! </strong></small>
                                                }
                                                onChange = {this.datePicker(e.name)}
                                                value = {
                                                    this.state.user.apps.filter( el => el.name === e.name )[0].expiry
                                                        ?  (this.state.user.apps.filter( el => el.name === e.name ))[0].expiry
                                                        : this.state.now
                                                } />

                                            </div> 

                                        : <small><i>data expirării este este disponibilă doar pentru aplicațiile active</i></small> }
                                
                                </Grid>
                            
                            </Grid>
                        </Paper>
                    )
                }) 
               
                : (<div>se incarcă aplicațiile și rolurile...</div>)}
            </Grid>
                
        )
        
    }

}

export default withStyles(styles)(UserApps);