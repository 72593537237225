import React from 'react';
import { withRouter } from 'react-router-dom';
import { isLoggedIn } from '../../Utils';
import Error from '../Error/Error';
import Config from '../../Config';
const  config = Config[process.env.NODE_ENV];

class Login extends React.Component {

    state = {token : null, err: null }

    async componentDidMount(props) {
        // console.log("login will receive:", this.props.match.params.token)
        let res = null;
        try {
            res = await isLoggedIn(this.props.match.params.token)
        } catch(err) { console.log(err); this.setState({err:err})}
        finally{
            if ( res.data && res.data.username ) {
                let hasAccess = res.data.apps.filter( elem => elem.name === config.APP_NAME )
                if (hasAccess.length > 0 ) {
                    res.data.token = this.props.match.params.token;
                    
                    localStorage.setItem(`${config.NAMESPACE}`, JSON.stringify(res.data));
                    this.props.history.push('/landing')
                } else {
                    this.setState({err:401})
                }
            } else {
                this.setState({err:401})
            }
        }
        
    }

    render() {
        return (
            <div>autentificare... {this.state.err ? <Error status = {this.state.err}/>:null}</div>
        )
    }
}

export default withRouter(Login);