import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {background:'lightblue', marginTop:'64px'}

}

const  OtherToolbar = (props) => {

    const { classes } = props;

  return (

      <div className={classes.root}>

          <AppBar position="fixed" className = {classes.appBar} >
          <Toolbar>
                {props.children}
          </Toolbar>
        </AppBar>

      </div>
    
  )

}

export default withRouter(withStyles(styles)(OtherToolbar));