import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Users from './components/AppContainer/Users/Users';
import User from './components/AppContainer/User/User';
import Apps from './components/AppContainer/Apps/Apps';
import AddUser from './components/AppContainer/User/AddUser';
import Landing from './components/Landing/Landing'
import AppEdit from './components/AppContainer/Apps/AppEdit';
import AppAdd from './components/AppContainer/Apps/AppAdd';

class Routes extends React.Component {

    render() {
        return (
            <Switch>
                <Route path = "/landing" component = {Landing} />
                <Route path = "/user-add" component = {AddUser} />
                <Route path="/user/:id" component={User} />
                <Route path="/users"  component={Users} />
                <Route path="/app-add"  component={AppAdd} />
                <Route path="/app/:id"  component={AppEdit} />
                <Route path="/apps"  component={Apps} />
                {/* <Route path="/add-user"  component={AddUser} /> */}

            </Switch>
        )
    }
}

export default Routes;